import React from 'react';
import Fade from 'react-reveal/Fade';
import {useHistory} from 'react-router-dom';
import phone from './img/phone.jpg';
import birds from './img/birds.png';
import nurseSticker from './img/nurse-gif.gif';
import stars from './img/stars.png';
import stickers from './img/sticker.png';

export default function Instagram({mobile = false, go = null}) {
    //const [done, setDone] = useState(false);
    //const [start, setStart] = useState(go);
    let history = useHistory();

    const make = () => {
        go();
        history.push('/create');
    };

    // done start
    return (
        <div id="create" className={"instagram"}>
            <div className="inner">
                <div className="insta1 three-columns">
                    <Fade>
                        <div className="column-3 phone-img">
                            <div style={{display: 'inline-block', position: 'relative'}}>
                                <img src={phone} alt="Phone Stickers" />
                                <div className="birds"><img src={birds} alt="Birds" /></div>
                                <div className="nurse-sticker"><img src={nurseSticker} alt="Nurse Sticker" /></div>
                                <div className="sticker"><img src={stickers} alt="Sticker" /></div>
                                <div className="stars"><img src={stars} alt="Stars" /></div>
                            </div>
                            
                        </div>
                    </Fade>
                    <Fade>
                        <div className="column-3">
                            <div className="c-flex">
                                <div>
                                    <div className="blue-text blue-text-space">Join The Ticker-Tape Parade</div>
                                    <div className="p-big">Montefiore collaborated with artist <a href="https://tristaneaton.com" target="_blank" rel="noopener noreferrer">Tristan Eaton</a> to create iconic art that captures the heroism of our nurses' efforts.</div>
                                    {mobile ? 
                                        <p>Click below to create your own hero image, or to try our interactive Instagram and Facebook filters.</p>
                                    :
                                        <p>Create your own hero image below to share with others. Or open nursesareheroes.org on your mobile device to try our interactive Instagram, Facebook filters and more.</p>
                                    }
                                
                                    <div className="buttons">
                                        <button className="cta cta-arrow" onClick={make}>
                                            <div className="circle circle-bold"></div>
                                            <div className="text text-bold">Make Your Hero Image</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}