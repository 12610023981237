import React from 'react';
//import Fade from 'react-reveal/Fade';
//import VideoPlayer from './VideoPlayer';

//import video from './img/video.jpg';
import overlay from './img/home.jpg';

export default function Home({visible = true}) {
   
    return (
        <div id="home" className="home">
            <div className="intro-text">
                <span>In honor of all the brave nurses.<br />Now and forever.</span>

                <div className="blue-text-space">Montefiore thanks you for healing New York.</div>
            </div>

            <div className="intro-nurse"><img src={overlay} alt="Nurse" /></div>
            {/*<div className="intro-video">
                <VideoPlayer ytid="n-HWmJLD85I" image={video} alt="Canyon of Heros, the ticker tape parade our nurses deserve." />
            </div>*/}
        </div>
    );
}