import React from 'react';

export default function CreatorDownload({mobile, downloadGif, downloadPoster }) {
    return (
        mobile ? (
            <div className="step-bottom-overlay step-bottom-overlay-full">
                <div>
                    <div className="mobile">Press and hold the image above to add to your photos</div>
                </div>
            </div>
        ) : (
            <div className="step-bottom-overlay">
                <div>
                    <button className="button btn btn-white" onClick={downloadPoster}>Download Hero Image</button> 
                </div>
                <div>
                    <button className="button btn btn-white" onClick={downloadGif}>Download Hero Gif</button> 
                </div>
            </div>
        )
    )
}