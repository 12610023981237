import React from 'react';
import Slider from 'rc-slider';

export default function CreatorScaleImage({setScale = null}) {
    const change = (c) => {
        setScale(c);
    };

    return (

        <div className="canvas-scale">
            <span>Scale</span>
            <Slider 
                min={-1} 
                max={1} 
                step={0.01} 
                defaultValue={0} 
                railStyle={{ backgroundColor: '#fff', height: 10}}
                trackStyle={{ backgroundColor: '#f12535', height:10}}
                handleStyle={{
                    borderColor: '#f12535',
                    backgroundColor: '#f12535',
                    height: 28,
                    width: 28,
                    marginTop: -9,
                }}
                onChange={change}
                />

                
        </div>
    );
}