import React from 'react';

export default function CreatorAddPhoto({textEntered, next = null, back = null}) {
    return (
        <div className="step-bottom-overlay">
            <div>
                <button className="button btn" onClick={back}>Back</button>
            </div>
            <div>
                <button className="button btn" onClick={next}>{textEntered ? <>Finish</> : <>Skip</>}</button>
            </div>
        </div>
    );
}