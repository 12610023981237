import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
  import App from './App';

  export default function R() {
      return (
          <Router>
              <Switch>
                  <Route exact path="/create"><App create={true} /></Route>
              </Switch>
              <Switch>
                  <Route exact path="/"><App create={false} /></Route>
              </Switch>
          </Router>
      );
  }