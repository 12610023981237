import * as THREE from 'three'
import React, { useState, useRef, useMemo } from 'react'
import { Canvas, useFrame } from 'react-three-fiber'

const colors = ['#f12535', '#43c7e7']
const MIN = 10;
const MAX = -8;

function Fatline() {
    const mesh = useRef()
    const [color] = useState(() => colors[parseInt(colors.length * Math.random())])
    const [position] = useState(() => [
        Math.random() * 14 - 7,
        Math.random() * 12 - 6,
        -Math.random() * 10 - 3
    ]);
    const [rotation] = useState(() => [
        Math.random() * 360,
        Math.random() * 360,
        Math.random() * 360
    ]);
    const [scale] = useState(() => Math.max(0.4, 0.9 * Math.random()));
    const [fallSpeed] = useState(() => ((Math.random() * 2 - 4) / 100));
    const [rotateSpeed] = useState(() => (Math.random() * 1 - 2) / 100);

    useFrame(() => {
        if(mesh.current.position.y < MAX) {
            mesh.current.position.y = MIN;
        }
        mesh.current.position.y += fallSpeed;

        mesh.current.rotation.y += rotateSpeed;
        mesh.current.rotation.x += rotateSpeed;
        mesh.current.rotation.y += rotateSpeed;
    })

    return (
        <mesh ref={mesh} scale={[scale, scale, scale]} position={position} rotation={rotation}>
            <planeGeometry attach="geometry" args={[1.25,0.75]} />
            <meshStandardMaterial attach="material" color={color} side={THREE.DoubleSide} />
        </mesh>
    )
}


function Scene({numLines}) {

  const lines = new Array(numLines).fill();

  let group = useRef()
  //let theta = 0
  // Hook into the render loop and rotate the scene a bit
  useFrame(() => {
   // group.current.rotation.set(0, 5 * Math.sin(THREE.Math.degToRad((theta += 0.032))), 0)
  })

  const everything = useMemo(() => {
    return lines.map((_, index) => (
      <Fatline key={index} />
    ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <group ref={group}>
      {everything}
    </group>
  )
}

export default function ThreeD({play = true, mobile = false}) {
  const numLines = mobile ? 75 : 125;

  return (
    play ? <div className="threed">
      <Canvas invalidateFrameloop={!play} camera={{ position: [0, 0, 2] }}>
        <ambientLight intensity={0.5} />
        <spotLight position={[0, 0, 5]} />
        <Scene numLines={numLines} />
      </Canvas>
    </div> : null 
  )
}
