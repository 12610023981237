import React, {useState, useEffect} from 'react';

export default function Steps({step = 0}) {
    const [stepText, setStepText] = useState('');
    const [p, setProgress] = useState(0);
    useEffect(()=> {
        switch(step) {
            default:
            case 0:
                setStepText('');
                break;
            case 1:
                setStepText('Choose your photo');
                setProgress(25);
                break;
            case 2:
                setStepText('Adjust your image to fit');
                setProgress(50);
                break;
            case 3:
                setStepText('Add a name to your image');
                setProgress(75);
                break;
            case 4:
                setStepText('Download your hero image');
                setProgress(100);
                break;
        }
    }, [step]);

    return (
        <div className={"steps step-count"+step}>
            <div>
                <div className="p-big">
                    <div className="step-blue">Step {step}</div>
                    {stepText}
                    {step === 1 || step === 2 ? <div className="disclaimer">By using this tool you agree you have read the <a href="/terms.html" target="_blank">T&Cs</a> and <a href="/privacy.html" target="_blank">Privacy</a>.</div> : null}
                </div>
                <div className="progress">
                    <span>{step} of 4</span>
                    <div className="progress-bar"><div className="progress-bar-inner" style={{width: p + '%'}}></div></div>
                </div>
            </div>
        </div>
    );
}