import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import Instagram from './img/social-instagram.svg';
import Facebook from './img/social-facebook.svg';
import Twitter from './img/social-twitter.svg';

//https://api.curator.io/v1/feeds/e6ad5238-efcf-4afc-8545-ea86f9576813/posts
/*

posts[0].image
posts[0].user_full_name
posts[0].originator_post_url
*/

export default function Curator() {
    const FEED_ID = 'e6ad5238-efcf-4afc-8545-ea86f9576813';
    const MAX_POSTS = 20;

    const [posts, setPosts] = useState([]);
    const slideShow = useRef();

    const settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        axios.get(`https://api.curator.io/v1/feeds/${FEED_ID}/posts`)
            .then(response => {
                let max = MAX_POSTS;
                if(response.data.posts.length < MAX_POSTS) {
                    max = response.data.posts.length;
                }

                const p = [];
                for(let x = 0 ; x < max ; x++) {
                    if(response.data.posts[x].image) {
                        p.push(response.data.posts[x]);
                    }
                }
                setPosts(p);
            });
    }, []);

    return(
        <div id="gallery" className="curator">
            <div className="inner">
                <div className="column-container">
                    <div className="column-half column-flex">
                        <Fade>
                        <div>
                            <div className="blue-text blue-text-space">Our New Heroes</div>
                            <div className="p-big">The ticker-tape parade our heroic nurses deserve.</div>
                            <div className="p-big hashtag">
                                #MonteHeroes<br />
                                #ThankYouNurses<br />
                                #NursesWeek
                            </div>
                        </div>
                        </Fade>
                    </div>
                    <div className="column-half">
                        <Fade>
                        <div id="curatorio" className="slider">
                            <div className="previous"><button onClick={() => slideShow.current.slickPrev()}></button></div>
                            <div className="next"><button onClick={() => slideShow.current.slickNext()}></button></div>
                            <Slider ref={slideShow} {...settings}>
                            {posts.map((post, p) => {
                                return <div className="c-slide" key={'post'+p}>
                                    <div className="square" style={{backgroundImage: 'url('+post.image+')'}}></div>
                                    <div className="meta">
                                        <>
                                            {post.network_name === 'Instagram' ? <img src={Instagram} alt="Instagram" /> : null}
                                            {post.network_name === 'Twitter' ? <img src={Twitter} alt="Twitter" /> : null}
                                            {post.network_name === 'Facebook' ? <img src={Facebook} alt="Facebook" /> : null}
                                            <a href={post.url} target="_blank" rel="noopener noreferrer">{post.user_full_name !== "" ? post.user_full_name : <>Link</>}</a>
                                        </>
                                    </div>
                                </div>
                            })}
                            </Slider>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}