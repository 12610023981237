import React, {useRef, useState} from 'react';
import * as loadImage from 'blueimp-load-image';

export default function CreatorAddPhoto({hasImage = false, setImg = null, next= null, width = 2048, height = 2048}) {
    const file = useRef();
    const [added, setAdded] = useState(false);

    const addPhoto = (e) => {
        e.preventDefault();

        const fileType = e.target.files[0].type;

        if(fileType === "image/png" || fileType === "image/gif" || fileType === "image/jpeg" || fileType === "image/jpg") {
            loadImage(e.target.files[0], img => {
                setImg(img.toDataURL());
                setAdded(true);
            },
            {
                orientation: true,
                canvas: true,
                maxWidth: width,
                maxHeight: height
            });
        } else {
            alert("Please choose an image file.");
        }
    };

    return (
        <div className="step-bottom-overlay">
            <div>
                <label htmlFor="fileupload" className="label-btn btn btn-add"><span className="text">Add Photo</span></label>
                <input type="file" ref={file} name="fileupload" id="fileupload" className="inputfile" accept="image/*" onChange={addPhoto} />
            </div>
            <div>
                <button className={added || hasImage ? "button btn" : "button btn button-disabled"} onClick={() => {
                    if(added || hasImage) { 
                        next();
                    }
                }}>Next</button>
            </div>
        </div>
    );
}