import React, { useState } from 'react';
import mobile from 'is-mobile';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import ThreeD from './ThreeD2';
import Header from './Header';
import CreatorFlow from './CreatorFlow';
import Home from './Home';

//import Video from './Video';
//import Mural from './Mural';
import Instagram from './Instagram';
import Curator from './Curator';
import Footer from './Footer';

import './App.scss';

function App({ create  }) {
  const [headerOn, setHeader] = useState(false);
  const [go, setGo] = useState(create);

  useScrollPosition(({ prevPos, currPos }) => {
    if(currPos.y*-1 > window.innerHeight * 0.8) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  });

  return (
    <div className="App">
      <Header on={headerOn && !go} />
      <Home />
      <CreatorFlow mobile={mobile()} go={create} onClose={()=>setGo(false)} />
      <Instagram mobile={mobile()} go={() => setGo(true)} />
      <Curator />
      <ThreeD play={!go && !create} mobile={mobile} />
    <Footer />
    </div>
  );
}

export default App;
