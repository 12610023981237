import React from 'react';
import logo from './img/WhiteLogo_ME_1.svg';
//import { Link } from 'react-router-dom';

export default function Header({on = false}){
    return (
        <header className={on ? 'header-on' : null}>
            <div className="logo">
                <img src={logo} alt="Montefiore | Einstein" />
            </div>

            {/*<div className="menu">
                <ul>
                    <li><Link to="/create">Create Poster</Link></li>
                    <li><a href="#gallery">Gallery</a></li>
                </ul>
            </div>*/}
        </header>
    );
}