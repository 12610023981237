import React from "react";
import logo from "./img/WhiteLogo_ME_1.svg";
import elevator from "./img/elevator.svg";

const currentYear = new Date().getFullYear();

export default function Curator() {
  return (
    <footer>
      <div className="inner">
        <div className="bar">
          <div className="bar-inner"></div>
        </div>
        <div className="column-container">
          <div className="column-half">
            <a href="https://montefioreeinstein.org/" target="_blank" rel="noopener noreferrer">
              <img src={logo} alt="Montefiore | Einstein" className="footer-logo" />
            </a>
          </div>
          <div className="column-half">
            <a href="https://montefioreeinstein.org/" target="_blank" rel="noopener noreferrer">
              montefioreeinstein.org
            </a>
            <br />
            <br />

            <a href="/privacy.html" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <br />
            <a href="/terms.html" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </div>

          <div className="elevator">
            <a href="#home">
              <img src={elevator} alt="Go To Top" />
            </a>
          </div>
        </div>
        <div className="bar-white"></div>
        &copy; {currentYear} Montefiore Medical Center 111 East 210th Street, Bronx, NY 10467
        718-920-4321
      </div>
    </footer>
  );
}
