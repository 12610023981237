/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {fabric} from 'fabric';
import FileSaver from 'file-saver';
import Filter from "bad-words";

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import GifGenerator from './GifGenerator';
import Analytics from './Analytics'
import './canvas-to-blob.js';

import Steps from './Steps';
import CreatorAddPhoto from './CreatorAddPhoto';
import CreatorScaleImage from './CreatorScaleImage';
import CreatorFinishButton from './CreatorFinishButton';
import CreatorDownload from './CreatorDownload';

import overlay from './img/overlay.png';
//import sticker from './img/final-overlay.png';

import './App.scss';

const profanityFilter = new Filter();

export default function CreatorFlow({mobile, go = null, onClose = null}) {
    const defaultNurseText = `Enter Name`;
    const [step, setStep] = useState(0);
    const [img, setImg] = useState(null);
    const [nurseName, setNurseName] = useState(null);
    const [gif, setGif] = useState(null);
    const [poster, setPoster] = useState(null);
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    const canvas = useRef(null);
    const f = useRef(null);
    const overlayImage = useRef();
    const userImage = useRef();
    const nurseInput = useRef();
    //const stickerImage = useRef();
    const finalConfettiImage = useRef();
    const initialScale = useRef();

    const stepRef = useRef(step);
    const targetRef = useRef();

    const HEIGHT = 1024;//mobile ? window.innerWidth : 600;
    const WIDTH = 1024; //mobile ? window.innerWidth : 600;

    const setupCanvas = () => {
        fabric.Image.fromURL(overlay, o => {
            o.set({
                id: 'overlay',
                hasBorders: false,
                hasControls: false,
                hasRotatingPoint: false,
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                selectable: false,
                defaultCursor: 'pointer',
                evented: false
            });
            o.scaleToHeight(WIDTH);
            o.scaleToWidth(WIDTH);
            overlayImage.current = o;

            f.current.add(overlayImage.current);
        });

        f.current.on('mouse:down', mouseDown);
    };

    const addImage = (i) => {
        setStep(2);
        if(userImage.current) {
            f.current.remove(userImage.current);
            f.current.renderAll();
            userImage.current = null;
        }

        fabric.util.loadImage(i, (imgObj) => {
            userImage.current = new fabric.Image(imgObj);
            //const center = userImage.current.getCenterPoint();
            
            userImage.current.scaleToWidth(WIDTH);
            userImage.current.set({
                id: 'userImage',
                //scaleX: 1,
                //scaleY: 1,
                hasBorders: false,
                hasControls: false,
                originX: 'center', 
                originY: 'center',
                left: WIDTH * 0.5,
                top: HEIGHT * 0.5
            });

            if(imgObj.width > imgObj.height) {
                userImage.current.scaleToHeight(WIDTH);
            } else {
                userImage.current.scaleToWidth(WIDTH);
            }
            //
            

            initialScale.current = {
                x: userImage.current.scaleX,
                y: userImage.current.scaleY
            };

            userImage.current.filters.push(new fabric.Image.filters.Grayscale());
            userImage.current.applyFilters();

            f.current.add(userImage.current);
            f.current.sendToBack(userImage.current);
            f.current.renderAll();
        });
    };

    const setScale = (s) => {
        console.log(initialScale.current.x, s, Math.abs(initialScale.current.x * (1+s)));
        userImage.current.set({
            scaleX: Math.abs(initialScale.current.x * (1+s) ),
            scaleY: Math.abs(initialScale.current.y * (1+s) )
        });

        f.current.renderAll();
    }

    const mouseDown = (e) => {
        console.log('mouse:down', stepRef.current);
        if(stepRef.current === 3 && nurseInput.current) {
            console.log('do it');
            f.current.setActiveObject(nurseInput.current);
            nurseInput.current.enterEditing()
            nurseInput.current.hiddenTextarea.focus();
            if(nurseInput.current.text === defaultNurseText) {
                nurseInput.current.set({text: ''});
                nurseInput.current.set({fill: '#fff'})
                f.current.renderAll();
            }
        }
    }

    const enableText = () => {
        console.log('enable text');

        nurseInput.current = new fabric.IText(defaultNurseText, { 
            fontFamily: 'Leitura',
            fill: '#666',
            left: WIDTH * 0.5, 
            top: 70,
            originX: 'center',
            textAlign: 'center',
            lineHeight: 1,
            fontSize: 70,
            cursorWidth: 8,
            cursorColor: '#FFF',
            hoverCursor: 'text',
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            selectionColor: 'rgba(255,255,255,0.9)',
            caching: false,
            fixedLineWidth: WIDTH,
            multiline: false,
            cursorHeightPercent: 0.7,
            cursorDeltaX: 0,
            cursorDeltaY: -12,
            maxLength: 30
        });

        f.current.add(nurseInput.current);
        f.current.renderAll();
/*
        nurseInput.current.on('editing:entered', () => {
            nurseInput.current.hiddenTextarea.setAttribute('maxlength', 17);
        });*/

        nurseInput.current.on('changed', (e) => {
            let t = nurseInput.current.text;
            t = t.replace(defaultNurseText, '');
            if(nurseInput.current.text.length >= 15) {
                t = t.substring(0,16);
            }
            //t = t.replace(/(\r\n|\n|\r)/gm, "");
            nurseInput.current.text = t;
            nurseInput.current.hiddenTextarea.value = t;

            if(nurseInput.current.text !== defaultNurseText) {
                nurseInput.current.set({fill: '#fff'})
                setNurseName(nurseInput.current.text);
            } else {
                nurseInput.current.set({fill: '#ACCFE1'});
                setNurseName(null);
            }

            nurseInput.current.setSelectionStart(nurseInput.current.text.length);
            nurseInput.current.setSelectionEnd(nurseInput.current.text.length);

            f.current.bringToFront(nurseInput.current);
            f.current.renderAll();
        });
    };

    //const addConfetti = async () => {
     //   return;
        /*
        return new Promise((resolve, reject) => {
            fabric.util.loadImage(process.env.PUBLIC_URL + '/frames/c_00040.png', (imgObj) => {
                finalConfettiImage.current = new fabric.Image(imgObj);
    
                finalConfettiImage.current.scaleToWidth(WIDTH);
                finalConfettiImage.current.set({
                    id: 'confettiImg',
                    hasBorders: false,
                    hasControls: false,
                    originX: 'center', 
                    originY: 'center',
                    left: WIDTH * 0.5,
                    top: HEIGHT * 0.5
                })
    
                f.current.add(finalConfettiImage.current);
                f.current.renderAll();

                resolve();
            });
        });*/
        
    //};

    const next = async () => {
        const s = step + 1;
        setStep(s);
        stepRef.current = s;

        if(s === 4) {
            //add confetti to final image
            setLoading(true);

            /*fabric.Image.fromURL(sticker, s => {
                s.set({
                    id: 'sticker',
                    hasBorders: false,
                    hasControls: false,
                    hasRotatingPoint: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    selectable: false,
                    defaultCursor: 'pointer',
                    evented: false,
                });
                stickerImage.current = s;
    
                f.current.add(stickerImage.current);*/

                // remove if nothing or a profanity

                if(nurseInput.current.text === defaultNurseText || profanityFilter.isProfane(nurseInput.current.text)) {
                    nurseInput.current.set({text: ''});
                    f.current.renderAll();
                }
                nurseInput.current.editable = false;
                f.current.discardActiveObject().renderAll();

                createGif();
            //});

            
        }
    };

    const back = () => {
        console.log('back')
        const s = step - 1;
        setStep(s);
        stepRef.current = s;

        if(s === 2) {
            f.current.remove(nurseInput.current);
            userImage.current.lockMovementX = false;
            userImage.current.lockMovementY = false;
        }
    };

    const dataURLtoBlob = (dataurl) => {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }

    const downloadPoster = () => {
        /*if(mobile) {
            window.open(canvas.current.toDataURL('png'), "_blank");
        } else {
            canvas.current.toBlob((blob) => {
                FileSaver.saveAs(blob, "nurse.gif");
            });
        }*/
        var blob = dataURLtoBlob(poster);
        FileSaver.saveAs(blob, "nurse.jpg");
        Analytics('Tool', 'Download Poster');
    };

    const downloadGif = () => {
        var blob = dataURLtoBlob(gif);
        FileSaver.saveAs(blob, "nurse.gif");
        Analytics('Tool', 'Download GIF');
    };

    function blobToDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function(e) {callback(e.target.result);}
        a.readAsDataURL(blob);
    }

    const createGif = async () => {
        //setLoading(true);
        const img = new Image(); //document.createElement('img');
        
        img.onload = async () => {
            const blob = await GifGenerator(img, mobile);

            blobToDataURL(blob, async (dataurl) => {
                setGif(dataurl);
                if(!mobile) {
                    await createPoster();
                }
                setLoading(false);
            });
        }

        img.src = canvas.current.toDataURL();
    };

    const createPoster = async () => {
        return new Promise((resolve, reject) => {
            fabric.util.loadImage(process.env.PUBLIC_URL + '/frames/1.png', (imgObj) => {
                finalConfettiImage.current = new fabric.Image(imgObj);
    
                finalConfettiImage.current.scaleToWidth(WIDTH);
                finalConfettiImage.current.set({
                    id: 'confettiImg',
                    hasBorders: false,
                    hasControls: false,
                    originX: 'center', 
                    originY: 'center',
                    left: WIDTH * 0.5,
                    top: HEIGHT * 0.5
                })
    
                f.current.add(finalConfettiImage.current);
                f.current.renderAll();

                setPoster(canvas.current.toDataURL());

                resolve();
            });
        });
    };

    //effects

    useEffect(()=> {
        if(f.current === null) {

            console.log('init');
            f.current = new fabric.Canvas(canvas.current, {
                backgroundColor: '#04193A',
                height: HEIGHT,
                width: WIDTH,
                selection: false,
                defaultCursor: 'default',
                preserveObjectStacking: true
            });

            f.current.setWidth(WIDTH);
            f.current.setHeight(HEIGHT);

            f.current.on('mouse:wheel', function(opt) {
                if(userImage.current) {
                    var delta = opt.e.deltaY;
                    let zoom = userImage.current.scaleX;
                    zoom = zoom + delta/200;
                    if (zoom > 5) zoom = 5;
                    if (zoom < 0.01) zoom = 0.01;
                    //canvas.setZoom(zoom);

                    userImage.current.set({
                        scaleX: zoom,
                        scaleY: zoom 
                    });

                    f.current.renderAll();
                }

                opt.e.preventDefault();
                opt.e.stopPropagation();
            });

            setupCanvas();
            
        }
        
    }, []);

    const resetAll = () => {
        setGif(null);
        setLoading(false);

        if(userImage.current) {
            f.current.remove(userImage.current);
            userImage.current = null;
        }

        if(nurseInput.current) {
            f.current.remove(nurseInput.current);
            nurseInput.current = null;
        }

        if(finalConfettiImage.current) {
            f.current.remove(finalConfettiImage.current);
            finalConfettiImage.current = null;
        }

        /*if(stickerImage.current) {
            f.current.remove(stickerImage.current);
            stickerImage.current = null;
        }*/
    };

    const close = () => {
        enableBodyScroll(targetRef.current);
        setStep(0);
        resetAll();
        //onClose();
        history.push('/');
    };

    useEffect(() => {
        if(img) {
            addImage(img);
        }
    }, [img]);

    useEffect(() => {
        if(step === 3) {
            console.log('step: ' + step);
            enableText();
            userImage.current.lockMovementX = true;
            userImage.current.lockMovementY = true;
        }

        Analytics('Tool', 'Step' + step);
    }, [step])

    useEffect(() => {
        if(go) {
            disableBodyScroll(targetRef.current);
            setStep(1);
        }
    }, [go]);

    return (
        <>
        {step >= 1 ? <button className="x" onClick={close}></button> : null}
        <div id="create" ref={targetRef} className={"creator step" + step}>
            
            <Steps step={step} />

            <div className="ui">
                
                <div className="canvas">
                    <canvas ref={canvas} />            
                    {loading ? <div className="loader-holder"><div className="loader"></div></div> : null}
                    {step === 4 && gif ? <img src={gif} alt="Final Sharable" className="confetti" /> : null}

                    {/*step === 4 && gif ? <img src={poster} alt="Final Sharable" className="confetti" /> : null*/}

                    {step === 4 && gif ? <div className="border"></div> : null}

                    {step === 2 ? <CreatorScaleImage setScale={setScale} /> : null }
                </div>
                {step === 1 || step === 2 ? <CreatorAddPhoto setImg={setImg} hasImage={img ? true : false} next={next} /> : null}
                {step === 3 ? <CreatorFinishButton setImg={setImg} textEntered={nurseName} back={back} next={next} /> : null}
                {step === 4 ? <CreatorDownload mobile={mobile} downloadPoster={downloadPoster} downloadGif={downloadGif} /> : null}
            </div>
        </div>
        </>
    );
}